<template>
  <div>
    <!--    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="4" :sm="24">
            <a-form-item label="状态">
              <a-select v-model="queryParam.status" placeholder="请选择" default-value="0">
                <a-select-option value="">全部</a-select-option>
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.TakeInventoryStatus">{{ val }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>-->
    <!--    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
    </div>-->

    <s-table
      ref="table"
      size="middle"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleRead(record)">详情</a>
        </template>
      </span>
    </s-table>
  </div>
</template>

<script>
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'
import { take_inventory_read } from '@/api/take_inventory'

export default {
  name: 'TableList',
  components: {
    STable
  },
  data () {
    return {
      loading: false,
      confirmLoading: false,
      current_bill_id: 0,
      // 创建窗口控制
      visible: false,
      mdl: {},
      payconfirmLoading: false,
      // 创建窗口控制
      payvisible: false,
      paymdl: {},
      dictionaries: Dictionaries,
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '展示商品',
          customRender: (text) => text || '-',
          align: 'center',
          dataIndex: 'exhibition_commodity_name'
        },
        {
          title: '展示仓库',
          align: 'center',
          customRender: (text) => text || '-',
          dataIndex: 'exhibition_warehouse_name'
        },
        {
          title: 'QID',
          align: 'center',
          customRender: (text) => text || '-',
          dataIndex: 'qid'
        },
        {
          title: '数量',
          align: 'center',
          customRender: (text) => text || '-',
          dataIndex: 'count'
        },
        {
          title: '状态',
          dataIndex: 'take_inventory_status',
          customRender: (text) => this.$Dictionaries.TakeInventoryCommodityStatus[text]
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return take_inventory_read(Object.assign(parameter, this.queryParam), this.$route.query.id)
          .then(({ data }) => {
            return data
          })
      }
    }
  },
  created () {
    console.log(this.$route.query.id)
  },
  methods: {
    handleRead (record) {
      console.log(record)
    },
    handleRefund (record) {
      console.log(record)
    }
  }
}
</script>
